import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { formatPrice } from 'gooten-components/src/utils/price';
import Select from 'gooten-components/src/components/shared/Select';
import Input from 'gooten-components/src/components/shared/Input';
import Button from 'gooten-components/src/components/shared/Button';
import Icon from 'gooten-components/src/components/shared/Icon';
import { COLORS } from 'gooten-components/src/constants';

const CustomerRefundForm = ({
  customerRefundReasons,
  customerRefundData,
  customerRefundHistory,
  submitCustomerRefund,
  showLoading,
  reasonCode,
  reasonSelect
}) => {
  const notRejectedRefunds = customerRefundHistory.filter(x => x.get('Status') !== false);

  const [submitText, setSubmitText] = useState('Refund');

  const refundItemRest =
    customerRefundData.get('customerOrderItemTotal') -
    notRejectedRefunds.reduce((agr, x) => agr + x.get('ItemRefund'), 0);

  const [itemAmount, setItemAmount] = useState(refundItemRest.toFixed(2));

  const refundItemTaxRest =
    customerRefundData.get('customerOrderItemTaxTotal') -
    notRejectedRefunds.reduce((agr, x) => agr + x.get('ItemTaxRefund'), 0);

  const [itemTaxAmount, setItemTaxAmount] = useState(refundItemTaxRest.toFixed(2));

  const refundShippingRest =
    customerRefundData.get('customerOrderShippingTotal') -
    notRejectedRefunds.reduce((agr, x) => agr + x.get('ShippingRefund'), 0);

  const [shippingAmount, setShippingAmount] = useState(refundShippingRest.toFixed(2));

  const refundShippingTaxRest =
    customerRefundData.get('customerOrderShippingTaxTotal') -
    notRejectedRefunds.reduce((agr, x) => agr + x.get('ShippingTaxRefund'), 0);

  const [shippingTaxAmount, setShippingTaxAmount] = useState(refundShippingTaxRest.toFixed(2));

  const allowRefund =
    (customerRefundData.get('allowMultipleRefunds') || notRejectedRefunds.size == 0) &&
    refundItemRest + refundItemTaxRest + refundShippingRest + refundShippingTaxRest > 0;

  const [errors, setErrors] = useState({});

  useEffect(() => {
    checkErrors();
  }, [reasonCode, itemAmount, itemTaxAmount, shippingAmount, shippingTaxAmount]);

  const createOption = option => (
    <option key={option.get('code')} value={option.get('code')}>
      {option.get('code')} - {option.get('name')}
    </option>
  );

  const onSelectReason = e => {
    reasonSelect(e.target.value);
  };

  const checkErrors = option => {
    let objs = errors;
    // eslint-disable-next-line
    if (reasonCode == '0') {
      objs = { ...objs, reasonCode: 'Please add refund reason' };
    } else {
      delete objs.reasonCode;
    }

    let refundTotal = 0;
    if (
      itemAmount == 0 &&
      itemTaxAmount == 0 &&
      shippingAmount == 0 &&
      shippingTaxAmount == 0 &&
      refundItemRest + refundItemTaxRest + refundShippingRest + refundShippingTaxRest > 0
    ) {
      objs = { ...objs, itemAmount: 'Please add refund amount' };
    } else if (itemAmount < 0) {
      objs = { ...objs, itemAmount: 'Please add positive refund amount' };
    } else if (itemAmount > refundItemRest) {
      objs = {
        ...objs,
        itemAmount: 'Available to Refund: ' + formatPrice(refundItemRest, 'USD {1}')
      };
    } else {
      delete objs.itemAmount;
      refundTotal += parseFloat(itemAmount);
    }

    if (itemTaxAmount < 0) {
      objs = { ...objs, itemTaxAmount: 'Please add positive refund amount' };
    } else if (itemTaxAmount > refundItemTaxRest) {
      objs = {
        ...objs,
        itemTaxAmount: 'Available to Refund: ' + formatPrice(refundItemTaxRest, 'USD {1}')
      };
    } else {
      delete objs.itemTaxAmount;
      refundTotal += parseFloat(itemTaxAmount);
    }

    if (shippingAmount < 0) {
      objs = { ...objs, shippingAmount: 'Please add positive refund amount' };
    } else if (shippingAmount > refundShippingRest) {
      objs = {
        ...objs,
        shippingAmount: 'Available to Refund: ' + formatPrice(refundShippingRest, 'USD {1}')
      };
    } else {
      delete objs.shippingAmount;
      refundTotal += parseFloat(shippingAmount);
    }

    if (shippingTaxAmount < 0) {
      objs = { ...objs, shippingTaxAmount: 'Please add positive refund amount' };
    } else if (shippingTaxAmount > refundShippingTaxRest) {
      objs = {
        ...objs,
        shippingTaxAmount: 'Available to Refund: ' + formatPrice(refundShippingTaxRest, 'USD {1}')
      };
    } else {
      delete objs.shippingTaxAmount;
      refundTotal += parseFloat(shippingTaxAmount);
    }

    if (refundTotal > 0) {
      setSubmitText('Refund ' + formatPrice(refundTotal, 'USD {1}'));
    }

    setErrors({ ...objs });
    return Object.keys(objs).length;
  };

  async function handleSubmit() {
    if (!checkErrors()) {
      submitCustomerRefund({
        itemAmount,
        itemTaxAmount,
        shippingAmount,
        shippingTaxAmount,
        reason: customerRefundReasons.find(r => r.get('code') === reasonCode)
      });
    }
  }

  const errorMessage = type =>
    Object.keys(errors).includes(type) && (
      <React.Fragment>
        <div className="error-field caption-text d-flex align-items-center">
          <Icon icon="alertCircle" small />
          {errors[type]}
        </div>
        <style jsx>{`
          color: ${COLORS.cayennePepper};
          fill: ${COLORS.cayennePepper};
        `}</style>
      </React.Fragment>
    );

  return (
    <>
      <div className="title-2">Refund</div>

      <div className="order-total">
        <div className="title-4">Item Total</div>
        <div className="cost-holder">
          <Input
            type="number"
            disabled={!allowRefund || refundItemRest <= 0}
            onChange={e => setItemAmount(e.target.value)}
            onBlur={e => setItemAmount(e.target.value)}
            noLabel
            value={itemAmount}
          />
          {errorMessage('itemAmount')}
        </div>
        <div className="amount-holder">
          Available to Refund: {formatPrice(refundItemRest, 'USD {1}')}
        </div>
      </div>
      <hr className="divider" />
      <div className="order-total">
        <div className="title-4">Item Tax Total</div>
        <div className="cost-holder">
          <Input
            type="number"
            disabled={!allowRefund || refundItemTaxRest <= 0}
            onChange={e => setItemTaxAmount(e.target.value)}
            onBlur={e => setItemTaxAmount(e.target.value)}
            noLabel
            value={itemTaxAmount}
          />
          {errorMessage('itemTaxAmount')}
        </div>
        <div className="amount-holder">
          Available to Refund: {formatPrice(refundItemTaxRest, 'USD {1}')}
        </div>
      </div>
      <hr className="divider" />
      <div className="order-total">
        <div className="title-4">Shipping Total</div>
        <div className="cost-holder">
          <Input
            type="number"
            disabled={!allowRefund || refundShippingRest <= 0}
            onChange={e => setShippingAmount(e.target.value)}
            onBlur={e => setShippingAmount(e.target.value)}
            noLabel
            value={shippingAmount}
          />
          {errorMessage('shippingAmount')}
        </div>
        <div className="amount-holder">
          Available to Refund: {formatPrice(refundShippingRest, 'USD {1}')}
        </div>
      </div>
      <hr className="divider" />
      <div className="order-total">
        <div className="title-4">Shipping Tax Total</div>
        <div className="cost-holder">
          <Input
            type="number"
            disabled={!allowRefund || refundShippingTaxRest <= 0}
            onChange={e => setShippingTaxAmount(e.target.value)}
            onBlur={e => setShippingTaxAmount(e.target.value)}
            noLabel
            value={shippingTaxAmount}
          />
          {errorMessage('shippingTaxAmount')}
        </div>
        <div className="amount-holder">
          Available to Refund: {formatPrice(refundShippingTaxRest, 'USD {1}')}
        </div>
      </div>
      <hr className="divider bold" />
      <div className="order-total">
        <div className="title-4">Order Total</div>
        <div className="cost-holder total-holder">
          {formatPrice(customerRefundData.get('customerOrderTotal'), 'USD {1}')}
        </div>
        <div className="amount-holder" />
      </div>

      {allowRefund ? (
        <>
          <div className="refund-reason">
            <div className="title-3 asterisk">Reason for the Refund</div>
            <div className="reasons-holder">
              <Select
                disabled={false}
                autoComplete="false"
                value={reasonCode}
                noLabel
                onChange={onSelectReason}
              >
                {reasonCode == '0' && <option>Select Reason</option>}
                {customerRefundReasons.map(createOption)}
              </Select>
              {errorMessage('reasonCode')}
            </div>
          </div>

          <div className="button-holder">
            <Button
              disabled={!!Object.keys(errors).length || showLoading}
              loading={showLoading}
              className="large-only button-primary large ml-2"
              onClick={handleSubmit}
            >
              {submitText}
            </Button>
            {!!Object.keys(errors).length && (
              <div className="error-field caption-text d-flex align-items-center">
                <Icon icon="alertCircle" small />
                Please fix errors above
              </div>
            )}
          </div>
        </>
      ) : (
        <div>
          <p>No refund transactions allowed.</p>
        </div>
      )}

      <hr />
      <style jsx>{`
        margin: 20px;
        width: 70%;
        display: flex;
        flex-direction: column;

        .refund-reason,
        .order-total,
        .refund-amount {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 0;
        }

        .order-total {
          margin-top: 0;
        }

        hr.divider {
          width: 70%;
          margin-top: 10px;
          margin-bottom: 10px;
        }

        hr.bold {
          height: 2px;
          border-width: 0;
          color: black;
          background-color: ${COLORS.neutralDark};
        }

        .title-3 {
          width: 30%;
        }

        .title-4 {
          width: 60%;
          margin: 0;
          padding-left: 40px;
          padding-top: 10px;
        }

        .cost-holder {
          width: 35%;
          margin: 0;
          padding-left: 40px;
        }

        .total-holder {
          padding-top: 10px;
        }

        .amount-holder {
          width: 50%;
          margin: 0;
          padding-left: 40px;
          padding-top: 10px;
        }

        .refund-reason {
          margin-left: 0;
        }

        .refund-amount {
          margin-left: 0;
          margin-top: 0;
        }

        .error-field {
          color: ${COLORS.cayennePepper};
          fill: ${COLORS.cayennePepper};
          flex-direction: row;
          width: 130%;
          margin-left: 0px;
          margin-bottom: 0px;
        }

        .asterisk {
          display: flow;
          width: 35%;
        }

        .asterisk::after {
          content: ' *';
          color: ${COLORS.coral};
        }

        .button-holder {
          width: 140px;
        }
      `}</style>
    </>
  );
};

CustomerRefundForm.propTypes = {
  customerRefundReasons: PropTypes.instanceOf(List).isRequired,
  submitCustomerRefund: PropTypes.func.isRequired,
  customerRefundHistory: PropTypes.object.isRequired,
  customerRefundData: PropTypes.object.isRequired,
  showLoading: PropTypes.bool.isRequired,
  reasonCode: PropTypes.string.isRequired,
  reasonSelect: PropTypes.func.isRequired
};

export default CustomerRefundForm;
