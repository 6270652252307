import { fetch, postJson } from 'gooten-components/src/utils/http';
import _fetch from 'isomorphic-fetch';
import Config from 'gooten-components/src/config';

const customerRefundReasons = [
  { code: 'RES001', name: 'Broken/Damaged' },
  { code: 'RES002', name: 'Did not receive - tracking says delivered' },
  { code: 'RES003', name: 'Incorrect cut' },
  { code: 'RES004', name: 'Lost in transit' },
  { code: 'RES005', name: 'No tracking number' },
  { code: 'RES006', name: 'OoSLA' },
  { code: 'RES007', name: 'Out of stock' },
  { code: 'RES008', name: 'Printed on wrong side' },
  { code: 'RES009', name: 'Received incomplete order' },
  { code: 'RES010', name: "Received part of my order and someone else's" },
  { code: 'RES011', name: "Received someone else's entire order" },
  { code: 'RES012', name: 'Returned' },
  { code: 'RES013', name: 'Shipping label created - not shipped' },
  { code: 'RES014', name: 'Shipping delay' },
  { code: 'RES015', name: 'Wrong product' },
  { code: 'RES016', name: 'Wrong size' },
  { code: 'RES017', name: 'Image orientation' },
  { code: 'RES018', name: 'CS courtesy refund/reprint' },
  { code: 'RES019', name: 'Sample refund' },
  { code: 'RES020', name: 'Competitor packaging' },
  { code: 'RES021', name: 'Shipping issue' },
  { code: 'RES022', name: 'Cancelled' },
  { code: 'RES023', name: 'Pulled from shipping' },
  { code: 'RES024', name: 'Incomplete Order Item' },
  { code: 'RES025', name: 'Blurry Print' },
  { code: 'RES026', name: 'Printed color off vs submitted image' },
  { code: 'RES027', name: 'Lines through printed image' },
  { code: 'RES028', name: 'Missing print location' },
  { code: 'RES029', name: 'Graphic Crooked, Off-center, Too High / Low' },
  { code: 'RES030', name: 'Ink Spot/Smudge' },
  { code: 'RES031', name: 'Distorted/stretched image' },
  { code: 'RES032', name: 'Ghosting/Residual images' },
  { code: 'RES033', name: 'Peeling image' },
  { code: 'RES034', name: 'Faded image' },
  { code: 'RES035', name: 'Scaled image' },
  { code: 'RES036', name: 'Wrong color product' },
  { code: 'RES037', name: 'Technology Issue' },
  { code: 'RES038', name: 'Bulk Discount' }
];

const getAuthQueryParams = () => ({
  Authorization: 'Bearer ' + Config.get('adminApiToken')
});

class CustomerRefundService {
  // gets customer refund data from local storage, passed in from order details...
  // validate and clear local storage
  getCustomerRefundData(orderId) {
    const data =
      localStorage.getItem('pio.customerRefundData') &&
      JSON.parse(localStorage.getItem('pio.customerRefundData'));
    localStorage.removeItem('pio.customerRefundData');

    return data && data.orderSummary.OrderId.toString() === orderId
      ? {
          orderSummary: data.orderSummary,
          allowMultipleRefunds: data.orderSummary.AllowMultipleCustomerRefunds,
          orderId: data.orderSummary.OrderId,
          customerOrderItemTotal: parseFloat(
            data.orderSummary.Meta['customer_item_total'] || '0'
          ),
          customerOrderItemTaxTotal: parseFloat(
            data.orderSummary.Meta['customer_item_tax_total'] || '0'
          ),
          customerOrderShippingTotal: parseFloat(
            data.orderSummary.Meta['customer_shipping_total'] || '0'
          ),
          customerOrderShippingTaxTotal: parseFloat(
            data.orderSummary.Meta['customer_shipping_tax_total'] || '0'
          ),
          customerOrderTotal: parseFloat(data.orderSummary.Meta['customer_total'] || '0')
        }
      : null;
  }

  getOrderCustomerRefundHistory(orderSafeId, partnerDetails) {
    const url = `${Config.get('api')}CustomerRefundHistory?recipeId=${
      partnerDetails.RecipeId
    }&partnerBillingKey=${encodeURIComponent(partnerDetails.ApiKey)}&id=${orderSafeId}`;
    return fetch(url, { method: 'GET' }).then(res => res.CustomerRefunds);
  }

  getPartnerDetails(partnerId) {
    const headers = { ...getAuthQueryParams() };
    const url = `${window.location.origin}/admin/api/PartnersApi/GetDetails?partnerId=${partnerId}`;
    return fetch(url, { method: 'GET', headers }).then(res => res);
  }

  submitCustomerRefund(request) {
    const url = `${Config.get('api')}customerRefundRequest`;

    return new Promise((resolve, reject) => {
      postJson(url, request.body, { queryParams: request.queryParams })
        .then(res => {
          if (!res.HadError) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getAllCustomerRefundReasons() {
    // return async for ability to move to backend in future
    return new Promise((resolve, reject) => {
      resolve(customerRefundReasons);
    });
  }
}

// singleton
export default new CustomerRefundService();
