import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../../../../components/shared/Button';
import Select from '../../../shared/Select';
import SearchInput from '../../../../components/shared/SearchInput';
import CancelButton from '../../../../components/shared/CancelButton';
import ApparelPanelItem from './ApparelPanelItem';
import Config from '../../../../config';
import './ApparelPanel.scss';
import { Map } from 'immutable';
const ApparelPanel = ({
    onBackButtonClick,
    allItems,
    gotoGroup,
    selectBatchOptions,
    selectedOptions,
    genderOptions,
    brandOptions,
    brandSelect,
    genderSelect,
    selectedBrandId,
    selectedGenderId,
    search,
    searchChanged
}) => {

    const [data, setData] = useState(allItems);

    const filterData = () => {
        let didBrandFiltering = 0;
        let resultAfterSearch = [];
        allItems.forEach(element => {
            if (element.name.toLowerCase().includes(search?.toLowerCase())) {
                resultAfterSearch.push(element);
            }
        });

        setData(resultAfterSearch);
        let resultAfterBrand = [];
        if (selectedBrandId && selectedBrandId != '') {
            didBrandFiltering++;

            resultAfterSearch.forEach(element => {
                element.options.forEach(item => {
                    if (item.name == "Brand" && item.value.id == selectedBrandId) {
                        resultAfterBrand.push(element);
                    }
                });
            });

            setData(resultAfterBrand);
        }

        let didGenderFiltering = 0;
        if (selectedGenderId && selectedGenderId != '') {
            didGenderFiltering++;

            let resultAfterGender = [];
            if (didBrandFiltering == 0) {
                resultAfterSearch.forEach(element => {
                    element.options.forEach(item => {
                        if (item.name == "Gender" && item.value.id == selectedGenderId) {
                            resultAfterGender.push(element);
                        }
                    });
                });
            }
            else {
                resultAfterBrand.forEach(element => {
                    element.options.forEach(item => {
                        if (item.name == "Gender" && item.value.id == selectedGenderId) {
                            resultAfterGender.push(element);
                        }
                    });
                });
            }
            setData(resultAfterGender);
        }

        if (didBrandFiltering == 0 && didGenderFiltering == 0 && search == '') {
            setData(allItems);
        }
    }
    useEffect(() => {
        filterData();
    }, [search, selectedBrandId, selectedGenderId]);

    const buttonsContainer = () => {
        return <div className='buttons-container'>
            <CancelButton
                className="create-new-go-back"
                ignoreConfirmation
                cancel={() => onBackButtonClick()} >
                {Config.get('editMode') ? 'Cancel' : 'Previous step'}
            </CancelButton>
            <Button className={'continue-button large button-cta'}
                disabled={selectedOptions.size == 0 }
                onClick={() => {
                    if(selectedOptions.size > 0) 
                        {
                            //if an option is selected (Model), we skip until group 3 for Apparel
                            gotoGroup(3);
                        };
                }}>
                Continue
            </Button>
        </div>
    }

    const onSelectBrand = e => {
        brandSelect(e.target.value);
    };

    const onSelectGender = e => {
        genderSelect(e.target.value);
    };


    return (
        <div className='apparel-panel-container'>
            <div className="row">
                <div className="col-sm-4 col-xs-4 xs-margin-top-20 search-box">
                    <SearchInput searchText={search} onSearchChange={(e) => { searchChanged(e) }} />
                </div>
                <div className="col-sm-3 col-xs-3 xs-margin-top-20">
                    <Select
                        label={"Gender"}
                        value={selectedGenderId ?? ''}
                        onChange={onSelectGender}
                    >
                        {genderOptions.map(option => <option key={option.id} value={option.id}>{option.name}</option>)}
                    </Select>
                </div>
                <div className="col-sm-3 col-xs-3  xs-margin-top-20">
                    <Select
                        label={"Brand"}
                        value={selectedBrandId ?? ''}
                        onChange={onSelectBrand}
                    >
                        {brandOptions.map(option => <option key={option.id} value={option.id}>{option.name}</option>)}
                    </Select>
                </div>
            </div>
            <div className='items-grid'>
                {data.map((item, index) => <ApparelPanelItem key={index} item={item}
                                                 selectBatchOptions={selectBatchOptions} 
                    selectedOptions={selectedOptions} />)}
            </div>
            {buttonsContainer()}
        </div>
    );
}

ApparelPanel.propTypes = {
  
    onBackButtonClick: PropTypes.func,
    allItems: PropTypes.array,
    selectedBrandId: PropTypes.string,
    selectedGenderId: PropTypes.string,
    gotoGroup: PropTypes.func.isRequired,
    selectBatchOptions: PropTypes.func.isRequired,
    selectedOptions: PropTypes.instanceOf(Map),
    genderOptions: PropTypes.array,
    brandOptions: PropTypes.array,
    brandSelect: PropTypes.func.isRequired,
    genderSelect: PropTypes.func.isRequired,
    search: PropTypes.string,
    searchChanged: PropTypes.func.isRequired,
};

export default ApparelPanel;